<template>
  <section class="basic-page section p-0">
    <div class="overflow-scroll">
      <figure :class="style" v-if="info.topVideo || info.image">
        <video v-if="info.topVideo" autoplay controls controlsList="nofullscreen nodownload" crossorigin="anonymous">
          <source :src="info.topVideo.url"/>
        </video>
        <img v-else-if="info.image" :src="info.image.sizes.mobile.url || info.image.sizes.thumbnail.url"  />

      </figure>
      <section class="section content p-5">
        <div class="title is-5 mb-3 pb-3">{{ info.title }} </div>
        <div class="text">
          <json-rich-text v-if="info.description" :text="info.description" :key="info.id" />
        </div>
      </section>
    </div>
    
    <div class="link-buttons px-4 pb-2 columns is-mobile" v-if="info.buttons.length">
      <div v-for="(button,index) in info.buttons"
        class="column"
        :class="buttonClass(index)"
        :key="button.id">
        <a :href="button.link"
          class="button is-fullwidth is-primary is-outlined"
          target="_blank">
          <d-icon v-if="button.icon" :pack="button.icon.pack" :icon="button.icon.name" size="is-small" class="mr-1">
          </d-icon>
          {{ button.label }}
        </a>
      </div>
    </div>

  </section>
</template>

<script>
import JsonRichText from '@/components/JsonRichText.vue';
import './BasicPage.scss'

export default {
  components: { JsonRichText },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    style() {
      if (this.info.topVideo) return 'is-16by9'
      else if (this.info.image) return 'image is-16by9'
      else return '';
    },
  },
  methods: {
    buttonClass(index) {
      if(this.info.buttons.length == 3 && index == 0) return 'is-full-mobile';
      if(this.info.buttons.length == 4) return 'is-half-mobile';
      return false
    }
  },
  mounted() {
    //
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.basic-page {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  .overflow-scroll {
    flex-grow: 1;
    height: 1px;
  }
  .title {
    border-bottom: solid 1px #E0E0E0;
  }

  img {
    object-fit: cover;
  }
  
  .column {
    padding: .5rem;
  }
  .columns:last-child {
    margin-bottom: 0;
  }
  .link-buttons {
    flex-wrap: wrap;
    background: $white-bis;
    box-shadow: 0px -6px 6px $white-bis;
  }

}
</style>