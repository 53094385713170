<template>
  <div class="slide-selector is-flex is-flex-direction-column">
    <div class="is-flex is-justify-content-space-between px-5 py-3">
      <div v-if="controller.hostControl">
        <div v-if="isGuide">
          {{ $t('exp_slider.chooseCardForGuests') }}
        </div>
        <div v-else>
          {{ $t('exp_slider.cardSelectedByHost') }}
        </div>
      </div>
      <div v-else>
        {{ $t('exp_slider.swipeAndScroll') }}
      </div>
      <div v-if="isGuide && !hideHostControlToggle" class="is-flex">
        <b-switch size="is-small" class="has-text-weight-bold" v-model="controller.hostControl"
          @click.native='controller.ToggleHostControl()'>
          <d-icon pack="fas" icon="users" size="is-small" :class="{ 'has-text-primary': controller.hostControl }" />
        </b-switch>
      </div>
    </div>
    <vue-glide v-if="info.slides.length" v-model="activeSlide" :key="sliderId" :perView="1" :peek="20" :gap="5"
      :class="{ 'locked': controller.hostControl && !isGuide }" @glide:swipe-end="SwipeEnded()"
      :swipeThreshold="EnableSwipe" :rewind="false">
      <vue-glide-slide v-for="slide in info.slides" :key="slide.id" class="pb-3">
        <b-button v-show="isGuide && controller.hostControl" class="pinupButton" size="is-small" type="is-primary"
          outlined @click="PinSlide()">
          <b-icon pack="fas" icon="thumbtack" size="is-small" class="mr-1" />{{ $t('exp_slider.pinUp') }}
        </b-button>
        <basic-page :info="slide" :key="slide.id" class="card" />
      </vue-glide-slide>
    </vue-glide>


    <div v-if="info.slides.length" class="bullets is-flex is-justify-content-center is-align-items-center py-3">
      <div v-for="(slide, index) in info.slides" :key="slide.id" class="bullet"
        :class="{ active: activeSlide == index, 'has-background-primary': playing.index == index }"
        @click="GoToSlide(index)">
        <svg v-if="playing.index == index" xmlns="http://www.w3.org/2000/svg" width="5" height="5"
          viewBox="0 0 55.127 61.182">
          <path fill="white"
            d="M18.095,1.349C12.579-1.815,8.107.777,8.107,7.134v46.91c0,6.363,4.472,8.952,9.988,5.791l41-23.514c5.518-3.165,5.518-8.293,0-11.457Z" />
        </svg>
      </div>
    </div>

  </div>
</template>

<script>
import BasicPage from './BasicPage.vue';
import 'vue-glide-js/dist/vue-glide.css';
import { Glide, GlideSlide } from 'vue-glide-js';
import '../VideoSelector.scss';

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    BasicPage
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
    hideHostControlToggle: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      controller: this.$parent.$parent,
      activeSlide: 0,
      playing: { index: null },
      sliderId: 0, // al cambiarlo fuerzo al slider a rerenderizarse
    };
  },
  computed: {
    isGuide() {
      return this.$store.getters["user/hasAdminAuthority"]
    },
    EnableSwipe() {
      const defaultValue = 80;
      if (this.isGuide) return defaultValue;
      if (this.controller.hostControl) return false;
      return defaultValue;
    }
  },
  watch: {
    EnableSwipe(newState, oldState) {
      if (newState != oldState) {
        this.sliderId++
      }
    }
  },

  sockets: {
    SetSlide(data) {
      if (this.isGuide) return
      console.log("🔒 SetSlide", data)
      this.activeSlide = data.slide
    },
  },
  methods: {
    SwipeEnded() {
      // if (this.isGuide && this.controller.hostControl) {
      //   this.$socket.client.emit('roomPlayersMessage', {
      //     type: "SetSlide",
      //     slide: this.activeSlide
      //   });
      // }
    },
    GoToSlide(index) {
      if (this.controller.hostControl && !this.isGuide) return
      this.activeSlide = index;
    },
    PinSlide() {
      console.log("📌 pin up slide", this.activeSlide)
      if (this.isGuide && this.controller.hostControl) {
        this.$socket.client.emit('roomPlayersMessage', {
          type: "SetSlide",
          slide: this.activeSlide
        });
      }
    }
  },
  mounted() { },
};
</script>
<style lang="scss" scoped>
.glide__slide {
  position: relative;
}

.pinupButton {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}
</style>
